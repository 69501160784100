import VMD from "@/plugins/firebase/model/vmd";
import {TEMPLATE} from "@/plugins/firebase/type";

export default class Explore extends VMD{
    constructor(options = {}) {
        super();
        Object.assign(this, {
            templateType: TEMPLATE.EXPLORE.code,
        }, options);
        Object.assign(this, options);
    }

    getFormattedPlayTime() {
        return super.getFormattedPlayTime();
    }
}