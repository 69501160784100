const TEMPLATE = {
    HOT_DEAL: {
        code: 0,
        name: '우리매장 핫딜'
    },
    HALF_PRICE:{
        code: 1,
        name: '반값초이스'
    },
    EXPLORE:{
        code: 2,
        name: '만능체험대'
    },
}

function getTemplateName(code){
    let find = '-';
    for (const [type, obj] of Object.entries(TEMPLATE)) {
        if(TEMPLATE[type].code === code){
            find = obj.name;
        }
    }
    return find;
}

function getTemplate(code){
    let find = '-';
    for (const [type, obj] of Object.entries(TEMPLATE)) {
        if(TEMPLATE[type].code === code){
            find = obj;
        }
    }
    return find;
}

export {TEMPLATE, getTemplateName, getTemplate}