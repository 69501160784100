import VMD from "@/plugins/firebase/model/vmd";
import {TEMPLATE} from "@/plugins/firebase/type";

export default class HotDeal extends VMD{
    constructor(options = {}) {
        super();
        Object.assign(this, {
            templateType: TEMPLATE.HOT_DEAL.code,
            retailPrice: '',
            discountedPrice: '',
            membershipPrice: '',
        }, options);
        Object.assign(this, options);
    }

    getFormattedPlayTime() {
        return super.getFormattedPlayTime();
    }

    getValidationScheme(){
        let scheme = super.getValidationScheme();
        Object.assign(scheme, {
            name: (value) => {
                return {
                    msg: '제품명을 입력해주세요.',
                    validation: value.length > 0,
                    type: 'string',
                }
            },
            retailPrice: (value) =>{
                return {
                    msg: '소비자가를 올바르게 입력해주세요',
                    validation: super.isNumeric(value) && Number(value) >= 0,
                    type: 'positiveNumber',
                }
            },
            // discountedPrice: (value) =>{
            //     return {
            //         msg: '할인가를 올바르게 입력해주세요',
            //         validation: super.isNumeric(value) && Number(value) >= 0,
            //         type: 'positiveNumber',
            //     }
            // },
            membershipPrice: (value) =>{
                return {
                    msg: '멤버십 할인가를 올바르게 입력해주세요',
                    validation: super.isNumeric(value) && Number(value) >= 0,
                    type: 'positiveNumber',
                }
            },
        });
        return scheme;
    }
}