<template>
    <div :id="isModal ? '' : 'contents'" class="contents vmd_manage_page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <h2 class="page_ttl">태블릿 VMD 관리</h2>
            <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="input_sec">
                <div class="template_type_wrap is_label_cont">
                    <div class="label_cont">
                        <p>템플릿 타입</p>
                    </div>
                    <div class="content_wrap">
                        <div class="input_area checkbox inline">
                            <label></label>
                            <div v-for="(item, key) in vmdTypes" class="input_box">
                                <label>
                                    <input type="checkbox" :value="item.code" v-model="searchVmdTemplate">
                                    <span>{{item.name}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="template_type_wrap is_label_cont">
                    <div class="label_cont">
                        <p>등록일자</p>
                    </div>
                    <div class="content_wrap">
                        <!--                        <span class="date date_start"><input type="text" name="date" id="date"
                                                                                     v-model="searchVmdCreationStart" readonly/></span>-->
                        <span class="date date_start">
                            <!--TODO: 2021-08-05 / CSS에서 Close버튼을 막고 있음. kima 확인 요망.-->
                            <datepicker
                                    v-model="searchVmdCreationStart"
                                    :clearable="true"
                            />
                        </span>
                        <em>~</em>
                        <!--                        <span class="date date_end"><input type="text" name="date" id="date2"
                                                                                   v-model="searchVmdCreationEnd" readonly/></span>-->
                        <span class="date date_end">
                            <datepicker
                                    v-model="searchVmdCreationEnd"
                                    :lowerLimit="searchVmdCreationEndLowerLimit"
                                    :clearable="true"
                            />
                        </span>

                    </div>
                </div>
                <div class="template_type_wrap is_label_cont">
                    <div class="label_cont">
                        <p>제품명</p>
                    </div>
                    <div class="content_wrap">
                        <div :class="`input_area`" class="inline">
                            <div class="input_box">
                                <input type="text" v-model="searchVmdName" placeholder="검색어를 입력해주세요.">
                            </div>
                        </div>
                        <div class="btn_group">
                          <button type="button" class="btn strong" @click="searchVmd">검색</button>
                          <button type="button" class="btn basic" @click="resetSearch">초기화</button>
                        </div>
                    </div>
                </div>

            </div>
            <Pagination :paginationClass="'board_sec'" :pageData="vmdList">
                <template #count>
                    <!-- s: count -->
                    <div class="count_wrap has_btn">
                        <div class="count">
                            검색 {{searchVmdCount}} / 전체 {{totalVmdCount}}
                        </div>
                        <div class="btn_group clearFix taR" v-if="!isModal">
                            <button type="button" class="btn basic h_s w_sm" @click="removeDialog()" :disabled="checkedHashs.length < 1">삭제
                            </button>
                            <button type="button" class="btn strong h_s w_sm" @click="redirect('/vmd/create')">저장
                            </button>
                        </div>
                    </div>
                    <!-- e: count -->
                </template>
                <template #table="tableData">
                    <!-- s: table -->
                    <!-- 게시글 있는 경우 (10개씩 노출) -->
                    <div class="tbl_wrap list">
                        <table class="tbl" v-if="tableData && tableData.currentPageData && tableData.currentPageData.length > 0">
                            <caption>멤버</caption>
                            <colgroup>
                                <col width="50"/>
                                <col width="50"/>
                                <col/>
                                <col/>
                                <col/>
                                <col width="100"/>
                                <col width="100"/>
                                <col width="100"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>
                                    <div class="input_area checkbox">
                                        <div class="input_box">
                                            <label>
                                                <input type="checkbox" v-model="selectAll">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </th>
                                <th>NO</th>
                                <th class="subject">이미지</th>
                                <th class="title">제품명</th>
                                <th>템플릿 타입</th>
                                <th>재생파일 수</th>
                                <th>재생시간</th>
                                <th>등록일시</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in tableData.currentPageData">
                                <td>
                                    <div class="input_area checkbox">
                                        <div class="input_box">
                                            <label>
                                                <input type="checkbox" :value="item.hash" v-model="checkedHashs" @change="emitCheckedHashs" :disabled="channelMap[item.hash]">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                                <td class="num">
                                    <span>{{index + 1}}</span>
                                </td>
                                <td>
                                    <!-- <img src="@/assets/img/test.jpg" alt="">-->
                                    <div class="img_box">
                                      <ImageView :src="getFirstFile(item)"/>
                                    </div>
                                </td>
                                <td class="title" @click="redirect('/vmd/modify/'+item.hash)">
                                    <span>
                                      <!-- Dev: a태그 추가 210802-->
                                      <a href="javascript:;">{{item.name}}</a>
                                    </span>
                                </td>
                                <td>
                                    <span>{{getTemplateName(item.templateType)}}</span>
                                </td>
                                <td>
                                    <span>{{item.getNumberOfFiles()}}</span>
                                </td>
                                <td>
                                    <span>{{item.getFormattedPlayTime()}}</span>
                                </td>
                                <td>
                                    <span>{{item.getCreationDate()}}</span> <span>{{item.getCreationTime()}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <!-- 검색시 해당 게시글 없는 경우 -->
                         <div class="no_result" v-if="!tableData || !tableData.currentPageData || (tableData && tableData.currentPageData && tableData.currentPageData.length < 1)">
                              <p>{{noListMessage}}</p>
                        </div>
                    </div>
                    <!-- e: table -->
                </template>
            </Pagination>
            <!-- e: board_sec -->
        </div>

        <!-- e: content_body -->
    </div>

    <!-- s: 삭제 확인 Dialog -->
    <Dialog :isOpen="removeDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="closeRemoveDialog()"
            v-on:yesDialog="deleteVmd()">
      <template #body>
        <h3>삭제 확인</h3>
        <p>선택된 항목을 삭제 하시겠습니까?</p>
      </template>
    </Dialog>
    <!-- e: 삭제 확인 Dialog -->

</template>

<script>
import VMD from '@/plugins/firebase/model/vmd';
import Datepicker from 'vue3-datepicker'
import {TEMPLATE, getTemplateName} from '@/plugins/firebase/type'
import Pagination from '@/components/pagination'
import ImageView from '@/components/imageView'
import HotDeal from "@/plugins/firebase/model/vmd/hotdeal";
import HalfPrice from "@/plugins/firebase/model/vmd/halfprice";
import Explore from "@/plugins/firebase/model/vmd/explore";
import * as buttonImage from "@/assets/img/content/ic_calendar.svg";
import moment from "moment";
import {createToast} from "mosha-vue-toastify";
import Dialog from "@/components/dialog";


export default {
    name: "firebase/test",
    layout: {
        name: 'admin',
    },
    components: {
        Pagination: Pagination,
        ImageView: ImageView,
        Datepicker: Datepicker,
        Dialog,
    },
    props: {
        isModal: {
            type: Boolean,
            default: false
        },
        modifyHash:{
            type: String,
            default: null
        }
    },
    computed: {
        searchVmdCreationEndLowerLimit() {

            let start = moment(this.searchVmdCreationStart);
            let end = moment(this.searchVmdCreationEnd);
            if (start.isAfter(end)) {
                this.searchVmdCreationEnd = this.searchVmdCreationStart;
            }
            return start.toDate();
        },
        checkedItemList() {
            let list = this.vmdList.filter(x => this.checkedHashs.includes(x.hash));
            return list;
        },
        selectAll: {
            get: function (){
                return this.vmdList ? this.checkedHashs.length === this.vmdList.length : false;
            },
            set: function (value){
                let selected = [];
                if(value){
                    this.vmdList.forEach((item)=>{
                        if(!this.channelMap[item.hash])
                            selected.push(item.hash);
                    });
                }
                this.checkedHashs = selected;
                this.emitCheckedHashs();
            }
        },
        vmdList() {
            let arr = [];
            for (const [hash, item] of Object.entries(this.vmd)) {
                let vmd;
                if (item.templateType === TEMPLATE.HOT_DEAL.code) {
                    vmd = new HotDeal();
                } else if (item.templateType === TEMPLATE.HALF_PRICE.code) {
                    vmd = new HalfPrice();
                } else if (item.templateType === TEMPLATE.EXPLORE.code) {
                    vmd = new Explore();
                }
                vmd.setDefaultInfo(item);
                vmd.hash = hash;
                arr.push(vmd);
            }
            arr = arr.sort((a, b) => b.creationDateTime.localeCompare(a.creationDateTime));
            console.log(arr);
            return arr;
        }
    },
    data() {
        return {
            vmdTypes: TEMPLATE,
            vmd_root_key: 'vmd',
            channel_root_key: 'channel',
            vmd: [],
            totalVmdCount: 0,
            searchVmdCount: 0,
            searchVmdTemplate: [],
            searchVmdCreationStart: null,
            searchVmdCreationEnd: null,
            searchVmdName: '',
            selected: '',
            selectedFile: null,
            checkedHashs: [],
            channelMap: {},
            noListMessage: '등록된 게시글이 없습니다',
            removeDialogState: false,
        }
    },
    methods: {
        emitCheckedHashs(){
            this.$emit('update-checked-items', {
                total: this.checkedItemList,
            });
        },
        removeDialog(){
          this.removeDialogState = true;
          document.documentElement.style.overflow = 'hidden';
        },
        closeRemoveDialog(){
          this.removeDialogState = false;
          document.documentElement.style.overflow = '';
        },
        deleteVmd(){
            if(this.checkedHashs.length > 0){
                const ref = this.$firebase.database().ref(this.vmd_root_key);
                this.checkedHashs.forEach((hash)=>{
                  console.log(hash)
                    ref.child(hash).remove();
                    delete this.vmd[hash];

                    // 2022-01-25 파일 삭제 임시 주석
                    //this.deleteFolderContents(hash);
                });
                this.toastSuccess(this.checkedHashs.length + ' 개 항목 삭제 완료');
                this.checkedHashs = [];
                this.removeDialogState = false;
                document.documentElement.style.overflow = '';
            }else{
                this.toastDanger('삭제할 항목이 없습니다');
            }
        },
        deleteFolderContents(path) {
            const ref =this.$firebase.storage().ref(path);
            ref.listAll()
                    .then(dir => {
                        dir.items.forEach(fileRef => {
                            this.deleteFile(ref.fullPath, fileRef.name);
                        });
                        dir.prefixes.forEach(folderRef => {
                            this.deleteFolderContents(folderRef.fullPath);
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });
        },
        deleteFile(pathToFile, fileName) {
            const ref = this.$firebase.storage().ref(pathToFile);
            const childRef = ref.child(fileName);
            childRef.delete()
        },
        getFirstFile(vmd) {
            if (vmd && vmd.files && vmd.files.length > 0) {
                return vmd.files[0];
            }
            return null;
        },
        searchVmd() {

            this.$firebase.database().ref(this.vmd_root_key).get().then((sn) => {
                const data = sn.val();
                if (data) {
                    for (const [hash, item] of Object.entries(data)) {
                        if (this.searchVmdTemplate.length > 0 && !this.searchVmdTemplate.includes(item.templateType)) {
                            delete data[hash];
                            continue;
                        }

                        if (this.searchVmdCreationStart) {
                            let m = moment(item.creationDateTime);
                            let start = moment(this.searchVmdCreationStart);
                            if (m.isBefore(start)) {
                                delete data[hash];
                                continue;
                            }
                        }

                        if (this.searchVmdCreationEnd) {
                            let m = moment(item.creationDateTime);
                            let start = moment(this.searchVmdCreationEnd);
                            if (m.isAfter(start)) {
                                delete data[hash];
                                continue;
                            }
                        }

                        if (this.searchVmdName) {
                            if (!item.name.includes(this.searchVmdName)) {
                                delete data[hash];
                            }
                        }
                    }
                    this.noListMessage = '검색 결과가 없습니다';
                    this.searchVmdCount = Object.keys(data).length;
                    this.vmd = data;
                }
                //this.vmd = sn.val() || {};
            })
        },
        resetSearch() {
            this.searchVmdTemplate = [];
            this.searchVmdCreationStart = '';
            this.searchVmdCreationEnd = '';
            this.searchVmdName = '';
            this.$firebase.database().ref(this.vmd_root_key).get().then((sn) => {
                const data = sn.val();
                if (data) {
                    this.vmd = data;
                }
            });
        },
        push() {
            this.$firebase.database().ref(this.vmd_root_key).push(new VMD());
        },
        update() {
            if (this.selected) {
                const item = this.vmd[this.selected];
                item.name = '변경2';
                this.$firebase.database().ref(this.vmd_root_key).child(this.selected).set(item);
            }
        },
        read() {
            this.$firebase.database().ref(this.vmd_root_key).once('value', (sn) => {
                this.vmd = sn.val();
            })
        },
        remove() {
            this.$firebase.database().ref(this.vmd_root_key).child(this.selected).remove();
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.selectedFile = files[0];
        },
        async uploadTest() {
            if (this.selected && this.selectedFile) {
                let downloadUrl = await this.upload(this.selected, this.selectedFile);
                console.log('Last Download URL', downloadUrl);
            }
        },
        async upload(hash, image) {
            let storageRef = this.$firebase.storage().ref();
            let imageRef = storageRef.child(hash).child(image.name);
            let uploadTask = imageRef.put(image);
            return new Promise(((resolve, reject) => {
                uploadTask.on('state_changed', (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
                            break;
                        case this.$firebase.storage.TaskState.RUNNING: // or 'running'
                            break;
                    }
                }, (error) => {
                    console.error('[ERROR] state_changed', error);
                    reject(error);
                }, () => {
                    // 완료 다운로드 예시
                    resolve(uploadTask.snapshot.ref.getDownloadURL());
                });
            }));
        },
        getTemplateName(code) {
            return getTemplateName(code);
        },
        clearCheckes(){
            this.checkedHashs = [];
        },
        test() {
            let vmd = new VMD();
            console.log(index);
        },
        printConsole(data) {
            console.log(JSON.stringify(data));
            return data;
        }
    },
    created() {
        this.noListMessage = '등록된 게시글이 없습니다';
        this.$firebase.database().ref(this.vmd_root_key).once('value', (sn) => {
            const data = sn.val();
            if (data) {
                this.vmd = data;
                this.totalVmdCount = Object.keys(data).length;
            }
        });
        if(!this.isModal){
            const channelRef = this.$firebase.database().ref(this.channel_root_key);
            channelRef.get().then((snapshot) => {
                snapshot.forEach((channels) => {
                    let thisChannelKey = channels.key;
                    let thisChannel = channels.val();
                    if(thisChannel && thisChannel.vmd){
                        thisChannel.vmd.forEach((vmd) => {
                            this.channelMap[vmd] = thisChannelKey;
                        });
                    }
                });
            });
        }
    },
}
</script>

<style scoped>

</style>
