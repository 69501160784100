import VMD from "@/plugins/firebase/model/vmd";
import {TEMPLATE} from "@/plugins/firebase/type";

export default class HalfPrice extends VMD{
    constructor(options = {}) {
        super();
        Object.assign(this, {
            templateType: TEMPLATE.HALF_PRICE.code,
            normalPrice:'',
            temporaryPrice:'',
            installmentMonth:12,
            installmentPrice:'',
        }, options);
        Object.assign(this, options);
    }

    isTemporaryFree(){
        return this.temporaryPrice === 0;
    }

    getFormattedPlayTime() {
        return super.getFormattedPlayTime();
    }

    getValidationScheme(){
        let scheme = super.getValidationScheme();
        Object.assign(scheme, {
            name: (value) => {
                return {
                    msg: '제품명을 입력해주세요.',
                    validation: value.length > 0,
                    type: 'string',
                }
            },
            normalPrice: (value) =>{
                return {
                    msg: '정상가를 올바르게 입력해주세요',
                    validation: super.isNumeric(value) && Number(value) >= 0,
                    type: 'positiveNumber',
                }
            },
            temporaryPrice: (value) =>{
                return {
                    msg: '일시납을 올바르게 입력해주세요',
                    validation: super.isNumeric(value) && Number(value) >= 0,
                    type: 'positiveNumber',
                }
            },
        });
        return scheme;
    }
}