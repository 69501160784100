import {TEMPLATE} from '@/plugins/firebase/type'
import moment from "moment";

export default class VMD {
    constructor(options = {}) {
        Object.assign(this, {
            templateType: TEMPLATE.HOT_DEAL.code,
            order: 0,
            name: '',
            subName: '',
            desc: ['', '', ''], // Dev: 210803 배열로 수정
            files: [],
            filesPlayTime: 0,
            imagePlayTime: 5,
            creationDateTime: '',
            lastModifiedDateTime: '',
        }, options);
        Object.assign(this, options);
    }

    getValidationScheme() {
        return {

        }
    }

    validate() {
        const scheme = this.getValidationScheme();
        const validate = (object) => Object
                .keys(scheme)
                .filter(key => !scheme[key](object[key]).validation)
                .map(key => {
                    return {
                        key: key,
                        scheme: scheme[key](object[key])
                    }
                });
        return validate(this);
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    getNumberOfFiles() {
        if (this.files) {
            return this.files.filter(x => x && true && typeof x !== 'undefined').length;
        }
        return 0;
    }

    getFormattedPlayTime() {
        const seconds = this.filesPlayTime;
        if (seconds) {
            let hour = Math.floor(seconds / 3600);
            let min = Math.floor((seconds % 3600) / 60);
            let sec = Math.floor(seconds % 60); /*Dev:210916 소수점 안나오게 수정*/
            return `${hour ? hour + '° ' : ''}${min ? min + '’ ' : ''}${sec ? sec + '”' : ''}`;
        }
        return '0”';
    }

    getCreationDate() {
        return moment(this.creationDateTime).format('YYYY-MM-DD');
    }

    getCreationTime() {
        return moment(this.creationDateTime).format('HH:mm:ss');
    }


    setDefaultInfo(vmd) {
        Object.assign(this, vmd);
        let arr = [null, null, null, null, null];
        for (let i = 0; i < 5; i++) {
            if (this.files[i]) {
                arr[i] = this.files[i];
            }
        }
        this.files = arr;
    }

    static fromJSON(json) {
        return Object.assign(new VMD(), json);
    }

}